[data-amplify-authenticator][data-variation="modal"] {
  background-image: url("../../assets/background-blur.png");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}

[data-amplify-authenticator][data-variation="modal"]::before {
  background-image: none;
}

:root {
  --amplify-colors-brand-primary-100: hsl(218deg 70% 15%);
  --amplify-colors-brand-primary-90: hsl(218deg 70% 25%);
  --amplify-colors-brand-primary-80: hsl(218deg 70% 53%);
  --amplify-colors-brand-primary-60: hsl(218deg 70% 70%);
  --amplify-colors-brand-primary-40: hsl(218deg 70% 87%);
  --amplify-colors-brand-primary-20: hsl(218deg 70% 94%);
  --amplify-colors-brand-primary-10: hsl(218deg 70% 98%);
}
