.veriff-text {
  border: 2px solid gray;
  transition-duration: unset;
  transition-timing-function: unset;
  transition-property: unset;
}

.veriff-submit {
  background-color: #003fae;
}

.veriff-text::placeholder {
  color: gray;
  opacity: 100%;
}
