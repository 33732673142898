.profile-section {
  min-height: 520px;
  box-sizing: border-box;
  padding: 12vh 3vw 5vh 1.5vw;
  border-radius: 0 0 7vw 0;
  background-color: #3370db;
  gap: 3vh;
  width: 15vw;
  max-height: 80vh;
}

.separator-line {
  margin-top: 5vh;
  margin-bottom: 5vh;
  width: 105%;
  color: #3370db;
  border-top: 0.3vh dashed white;
}
